export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** The `Date` scalar represents an ISO-8601 compliant date type. */
  Date: { input: any; output: any; }
  /** The `DateTime` scalar represents an ISO-8601 compliant date time type. */
  DateTime: { input: any; output: any; }
  /** Represents an instant on the global timeline, with nanosecond resolution. */
  Instant: { input: any; output: any; }
  /**
   * LocalDate is an immutable struct representing a date within the calendar, with
   * no reference to a particular time zone or time of day.
   */
  LocalDate: { input: any; output: any; }
  /** A date and time in a particular calendar system. */
  LocalDateTime: { input: any; output: any; }
  /** LocalTime is an immutable struct representing a time of day, with no reference to a particular calendar, time zone or date. */
  LocalTime: { input: any; output: any; }
  /**
   * The `Long` scalar type represents non-fractional signed whole 64-bit numeric
   * values. Long can represent values between -(2^63) and 2^63 - 1.
   */
  Long: { input: any; output: any; }
  UUID: { input: any; output: any; }
  /**
   * A LocalDateTime in a specific time zone and with a particular offset to distinguish between otherwise-ambiguous instants.
   * A ZonedDateTime is global, in that it maps to a single Instant.
   */
  ZonedDateTime: { input: any; output: any; }
};

export type AddTemplateInput = {
  content: Scalars['String']['input'];
  name: Scalars['String']['input'];
  subject: Scalars['String']['input'];
};

export enum ApplicantState {
  Declined = 'DECLINED',
  Hired = 'HIRED',
  Interviewed = 'INTERVIEWED',
  Invited = 'INVITED',
  New = 'NEW',
  Screened = 'SCREENED'
}

/** Defines when a policy shall be executed. */
export enum ApplyPolicy {
  /** After the resolver was executed. */
  AfterResolver = 'AFTER_RESOLVER',
  /** Before the resolver was executed. */
  BeforeResolver = 'BEFORE_RESOLVER',
  /** The policy is applied in the validation step before the execution. */
  Validation = 'VALIDATION'
}

export enum JobCategory {
  Apprenticeship = 'APPRENTICESHIP',
  Fulltime = 'FULLTIME',
  Internship = 'INTERNSHIP',
  Unknown = 'UNKNOWN'
}

export enum JobDepartment {
  Design = 'DESIGN',
  Development = 'DEVELOPMENT',
  Miscellaneous = 'MISCELLANEOUS'
}

export type LoginUserInput = {
  gitlabToken: Scalars['String']['input'];
  redirectUrl: Scalars['String']['input'];
};

export type RemoveTemplateInput = {
  templateId: Scalars['UUID']['input'];
};

export enum StatisticTrendType {
  Down = 'DOWN',
  Flat = 'FLAT',
  Up = 'UP'
}

export type UpdateApplicantDataInput = {
  email: Scalars['String']['input'];
  id: Scalars['UUID']['input'];
  jobCategory: JobCategory;
  name: Scalars['String']['input'];
  position: Scalars['String']['input'];
  textBody: Scalars['String']['input'];
};

export type UpdateApplicantNotesInput = {
  id: Scalars['UUID']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateApplicantStatusInput = {
  id: Scalars['UUID']['input'];
  newState: ApplicantState;
};

export type UpdateTemplateInput = {
  input: AddTemplateInput;
  templateId: Scalars['UUID']['input'];
};
